import {IColumn, IDetailsColumnProps, IRenderFunction} from '@fluentui/react';

import {XLSXValue} from '../../../helpers/excel';
import {Links} from '../../../services/generated';
import {EnhancedColumn} from './ColumnTypes/EnhancedColumn';

export enum SpecialColumnKey {
  Add = 'add',
  Remove = 'remove',
  AuditableEdit = 'auditableEdit',
  Edit = 'edit',
  Delete = 'delete',
  Select = 'select',
  EditDocIdLand = 'editDocIdLand'
}

export enum ColumnKey {
  Id = 'id',
  Add = 'add',
  Edit = 'edit',
  Delete = 'delete',
  Active = 'active',
  Removed = 'removed',
  Order = 'order',
  Action = 'action',
  Title = 'title',
  DocumentId = 'documentId',
  OwnerProcessId = 'ownerProcessId',
  DocumentKind = 'documentKind',
  Revision = 'revisionId',
  Auditable = 'auditable',
  AuditableEdit = 'auditableEdit',
  OperatingUnit = 'operatingUnit',
  DocumentOwner = 'documentOwner',
  Content = 'content',
  Function = 'function',
  FunctionString = 'functionString',
  Specialty = 'specialty',
  ProjectSizeCategory = 'projectSizeCategory',
  ApprovedDate = 'approved',
  SecurityLevel = 'securityLevel',
  Language = 'languageCode',
  IsDocument = 'isDocument',
  Unit = 'unit',
  UnitTitle = 'unitTitle',
  Library = 'library',
  TypeString = 'typeString',
  IsExternal = 'isExternal',
  ContentTypeString = 'contentTypeString',
  Industry = 'industry',
  NextUpdate = 'nextUpdate',
  ProcessOwner = 'processOwner',
  Url = 'url',
  RawUrl = 'rawUrl',
  PreparedUrl = 'preparedUrl',
  Description = 'description',
  Modified = 'modified',
  Processes = 'processes',
  ModifiedBy = 'modifiedBy',
  Process = 'process',
  LanguageShort = 'language',
  RevisionString = 'revision',
  ContentType = 'contentType',
  Published = 'published',
  DraftExists = 'DraftExists',
  ExternalLinkTypeString = 'externalLinkTypeString',
  IsBroken = 'isBroken',
  Created = 'created',
  CreatedBy = 'createdBy',
  CreatedByProcess = 'createdByProcess',
  CreatedByProcessOwner = 'createdByProcessOwner',
  StatusString = 'statusString',
  Reason = 'reason',
  Working = 'working',
  RelevantTo = 'relevantTo',
  KeyWords = 'keyWords',
}

export enum ColumnType {
  Boolean,
  String,
  Richtext,
  Select,
  MultiSelect,
  Date,
  DateTime,
  UserDto,
  Open,
  Toggle,
  Other,
  Processes,
  LinksList,
  UserIdColumn,
  Dropdown,
  KeyWords
}

export enum BooleanFilters {
  All = 'All',
  True = 'True',
  False = 'False'
}

export interface BaseItem {
  [key: string]: string | number | null | Links[] | boolean | undefined | any;
  id: number;
  ownerProcessId?: number;
  active?: boolean;
  order?: number;
}

export interface IEnhancedColumn<Item extends BaseItem> {
  key: (keyof Item | SpecialColumnKey) & string;
  name: string;
  minWidth?: number;
  maxWidth?: number;
  flexGrow?: number;
  isResizable?: boolean;
  isCollapsible?: boolean;
  data?: typeof EnhancedColumn;
  defaultFilterValue?: string | string[];
  onRender?: (item: Item, index?: number, column?: IColumn) => JSX.Element;
  onRenderHeader?: IRenderFunction<IDetailsColumnProps>;
  onColumnClick?: (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => void;
  onFilterChange?: (filter: string | string[]) => void;
  exportToExcel?: boolean;
  excelFileName?: string;
  onExportToExcel?: (item: Item, column?: IEnhancedColumn<Item>) => XLSXValue;
  tooltip?: string;
}

export interface IEnhancedColumnComplete<Item extends BaseItem> extends IEnhancedColumn<Item> {
  minWidth: number;
  data: typeof EnhancedColumn;
}

export interface IEnhancedDetailsListProps<
  Item extends BaseItem,
  ChangedItem extends BaseItem,
  ItemToAdd extends BaseItem
> {
  allowAdd?: boolean;
  addLabel?: string;
  columns: IEnhancedColumn<Item>[];
  columnsForAdd?: IEnhancedColumn<ItemToAdd>[];
  onCreateItemClick?: () => void;
  hideSearch?: true;
  frozen?: boolean;
  frozenClassName?: string;
  isAddModal?: true;
  items: Item[];
  itemsToAdd?: ItemToAdd[];
  changedItems?: ChangedItem[];
  mapChangedItemToItem?: (changedItem: ChangedItem) => Item;
  mapNewItemToChangedItem?: (newItem: ItemToAdd) => ChangedItem;
  ownerProcessId?: number;
  pageSize?: number;
  newItemsIds?: number[];
  addRowClass?: (item: ChangedItem) => string | undefined;
  className?: string;
  excelExport?: boolean;
  manageAuditable?: boolean;
  itemsFiltered?: boolean;
  excelFileName?: string;
  onManageAuditable?: () => void;
}
